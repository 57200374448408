<template>
	<Alert :type="prompt.info" v-for="prompt in sharedState.circleprompts" :key="prompt.id">
		{{prompt.title}}
		<!--  <template #desc>{{prompt.detail}}</template>	 -->
		<div @click.stop="upcirclestatus(prompt.id)" style="width:80px;height:80px;margin:0 auto">
			<Result type="success"></Result>
		</div>
	</Alert>
</template>
<script>
	import Store from '../store'
	export default {
		data() {
			return {
				sharedState: Store.state
			}
		},
		methods: {
			upcirclestatus(id) {

				this.$Modal.confirm({
					title: '确认结束周期提醒？',
					onOk: () => {

						if (Store.upcirclestatus(id)) {
							this.$Message.success('更新成功')
							Store.showAll('showtodayprompt')
						} else {
							this.$Message.error('更新失败')
						}

					},
					onCancel: () => {
						this.$Message.info('已取消');
					}
				});


			}
		},
	}
</script>