<template>
    <Alert :type="prompt.info" v-for="prompt in sharedState.todayprompt" :key="prompt.id">
        {{prompt.title}}
        <template #desc>{{prompt.detail}}</template>
    </Alert>

</template>
<script>
	import Store from '../store'
    export default {
        data(){
			return{
				sharedState:Store.state
			}
		},
		computed:{
			
		},
		mounted(){
			Store.showAll('showtodayprompt')
		}
    }
</script>
