import {
	reactive
} from 'vue'


export default {

	state: reactive({

		isLogin: true,
		isLayout: false,
		isRegister: false,
		user: '',
		cacheKey: '',
		token: '',
		things: [],
		prompts: [],
		todayprompt: [],
		circleprompts: []

	}),
	/* 	setRegister() {
			this.state.isLogin = false
			this.state.isRegister = true
		}, */
	backLogin() {
		this.state.isLogin = true
		this.state.isRegister = false
	},
	/* 	setLogin() {

			this.state.isLogin = false
			this.state.isLayout = true

			window.localStorage.setItem("isLogin", false)
			window.localStorage.setItem("isLayout", true)

		}, */

	setToken(user, cacheKey, token) {

		this.state.user = user
		this.state.cacheKey = cacheKey
		this.state.token = token

		window.localStorage.setItem('stateuser', user)
		window.localStorage.setItem('cacheKey', cacheKey)
		window.localStorage.setItem('token', token)

	},
	setLogout() {

		this.state.user = ''
		this.state.token = ''
		this.state.isLogin = true
		this.state.isLayout = false
		window.localStorage.removeItem('stateuser')
		window.localStorage.removeItem('token')
		window.localStorage.removeItem("isLogin")
		window.localStorage.removeItem("isLayout")


		let url = 'abc/logout/' + this.state.cacheKey
		fetch(url).then(response => response.json()).then(data => console.log(data.message))
	},
	updateUser(user, cacheKey, token) {
		this.state.user = user
		this.state.cacheKey = cacheKey
		this.state.token = token
	},
	showAll(type) {

		let url = 'abc/' + type + '/0/' + this.state.user + '/' + this.state.cacheKey + '/' + this.state.token
		fetch(url)
			.then(response => response.json())
			.then(data => {
				if (data.status == 1) {
					if (type == 'showallthing') {
						this.state.things = []
						this.state.things.push(...data.data);
					} else if (type == 'showallprompt') {
						this.state.prompts = []
						this.state.prompts.push(...data.data);
					} else if (type == 'showtodayprompt') {
						this.state.todayprompt = []
						this.state.todayprompt.push(...data.data);
					} else if (type == 'showcircleprompt') {
						this.state.circleprompts = []
						this.state.circleprompts.push(...data.data)
					}

				} else {

					this.$Message['error']({
						background: true,
						content: data.message
					});
				}
			});
	},
	getIndex(arrays, obj) {
		var i = arrays.length;
		while (i--) {
			if (arrays[i].id == obj) {
				return i;
			}
		}
		return false;
	},
	upstatus(id) {

		let url = 'abc/upstatus' + '/0/' + id + '/' + this.state.cacheKey + '/' + this.state.token
		fetch(url)
			.then(response => response.json())
			.then(data => {
				if (data.status == 1) {

					let index = this.getIndex(this.state.things, id)
					this.state.things.splice(index, 1)
					if (window.localStorage.getItem("flag") != null) {
						window.localStorage.removeItem("flag")
					}
					window.localStorage.setItem("flag", "1") //成功

				} else {
					if (window.localStorage.getItem("flag") != null) {
						window.localStorage.removeItem("flag")
					}
					window.localStorage.setItem("flag", "0") //失败

				}
			});
		let flag = window.localStorage.getItem("flag")
		if (flag == "1") {
			return true
		} else {
			return false
		}
	},
	upcirclestatus(id) {

		let url = 'abc/upcirclestatus' + '/0/' + id + '/' + this.state.cacheKey + '/' + this.state.token
		fetch(url)
			.then(response => response.json())
			.then(data => {

				if (data.status == 1) {
					let index = this.getIndex(this.state.circleprompts, id)
					this.state.circleprompts.splice(index, 1)
					if (window.localStorage.getItem("cflag") != null) {
						window.localStorage.removeItem("cflag")
					}
					window.localStorage.setItem("cflag", "1") //成功

				} else {
					if (window.localStorage.getItem("cflag") != null) {
						window.localStorage.removeItem("cflag")
					}
					window.localStorage.setItem("cflag", "0") //失败

				}

			});

		let flag = window.localStorage.getItem("cflag")
		if (flag == "1") {
			return true
		} else {
			return false
		}
	},
	uppromptstatus(id) {

		let url = 'abc/uppromptstatus' + '/0/' + id + '/' + this.state.cacheKey + '/' + this.state.token
		fetch(url)
			.then(response => response.json())
			.then(data => {

				if (data.status == 1) {
					let index = this.getIndex(this.state.prompts, id)
					this.state.prompts.splice(index, 1)
					if (window.localStorage.getItem("pflag") != null) {
						window.localStorage.removeItem("pflag")
					}
					window.localStorage.setItem("pflag", "1") //成功

				} else {
					if (window.localStorage.getItem("pflag") != null) {
						window.localStorage.removeItem("pflag")
					}
					window.localStorage.setItem("pflag", "0") //失败

				}

			});

		let flag = window.localStorage.getItem("pflag")
		if (flag == "1") {
			return true
		} else {
			return false
		}
	},
}