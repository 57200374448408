<template>
	<Form ref="formItem" :model="formItem" :rules="ruleValidate" :label-width="80">
		<FormItem label="事项标题" prop="title">
			<Input v-model="formItem.title" placeholder="请输入事项标题"></Input>
		</FormItem>
		<FormItem label="事项说明" prop="detail">
			<Input v-model="formItem.detail" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
				placeholder="请输入事项说明"></Input>
		</FormItem>
		<FormItem label="是否提醒" prop="isprompt">
			<i-switch v-model="formItem.isprompt" size="large">
				<template #open>
					<span>YES</span>
				</template>
				<template #close>
					<span>NO</span>
				</template>
			</i-switch>
		</FormItem>

		<FormItem label="提醒时间" v-show="formItem.isprompt">
			<Row>
				<Col span="5">
				<FormItem prop="date">
					<DatePicker type="date" format="yyyy-MM-dd" placeholder="请选择日期" v-model="formItem.date">
					</DatePicker>
				</FormItem>
				</Col>
				<Col span="2" style="text-align: center">-</Col>
				<Col span="5">
				<FormItem prop="time">
					<TimePicker type="time" placeholder="请选择时间" v-model="formItem.time"></TimePicker>
				</FormItem>
				</Col>
			</Row>
		</FormItem>

		<FormItem label="完成状态" prop="status">
			<RadioGroup v-model="formItem.status">
				<Radio label="done" disabled>已完成</Radio>
				<Radio label="notdo" disabled>未完成</Radio>
			</RadioGroup>
		</FormItem>


		<FormItem>
			<Button type="primary" :disabled="subflag" @click="handleSubmit('formItem')">提交</Button>
			<Button style="margin-left: 8px" @click="goback">返回</Button>
		</FormItem>
	</Form>
</template>
<script>
	import Store from '../store'

	export default {
		data() {
			return {
				subflag: false,
				formItem: {
					title: '',
					status: 'notdo',
					isprompt: false,
					date: '',
					time: '',
					detail: ''
				},
				ruleValidate: {
					title: [{
						required: true,
						message: '事项标题不能为空',
						trigger: 'blur'
					}],
					detail: [{
						type: 'string',
						max: 200,
						message: '最多不能超过200字',
						trigger: 'blur'
					}],
					date: [{
						type: 'date',
						trigger: 'change'
					}],
					time: [{
						type: 'string',
						trigger: 'change'
					}]
				},
				sharedState: Store.state

			}
		},
		props:["id"],
		mounted(){
			this.showThing()
		},
		updated(){
			this.showThing()
							
		},
		computed: {
			prompttime() {
				let date = new Date(this.formItem.date)
				let year = date.getFullYear()
				let month = Number(date.getMonth()) + 1
				month = month < 10 ? '0' + month : month
				let day = date.getDate()
				day = day < 10 ? '0' + day : day
				date = [year, month, day].join('-')

				return date + ' ' + this.formItem.time
			},
			randinfo() {
				let info = ['info', 'success', 'warning', 'error']
				let num = Math.floor(Math.random() * 4)
				return info[num]
			}
		},
		methods: {

			handleSubmit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						this.subflag = true;
						const data = {
							'id':this.id,
							'title': this.formItem.title,
							'detail': this.formItem.detail,
							'isprompt': this.formItem.isprompt,
							'status': this.formItem.status,
							'prompttime': this.prompttime,
							'nexttime': this.prompttime,
							'info': this.randinfo,
							'user': Store.state.user,
							'cacheKey': Store.state.cacheKey,
							'token': Store.state.token,
							'islogin': 0 //标明非登录
						};
						fetch('abc/editthing', {
								method: 'post',
								body: JSON.stringify(data),
								headers: {
									'Content-Type': 'application/json'
								}
							})
							.then(response => response.json())
							.then(data => {
								if (data.status == 1) {
									this.$Message.success('提交成功!');
									setTimeout(() => this.subflag = false, 1000)
									Store.showAll('showallthing')
								} else {
									console.log(data.message)
									this.$Message['error']({
										background: true,
										content: data.message
									});
									setTimeout(() => this.subflag = false, 1000)
								}
							});

					} else {
						this.$Message.error('验证不通过!');
					}
				});
			},
			goback(){
				this.$emit('goback')
			},
			showThing(){
				let user=this.sharedState.user
				let cacheKey=this.sharedState.cacheKey
				let token=this.sharedState.token
			
				fetch(`abc/showonething/0/${this.id}/${user}/${cacheKey}/${token}`, {
								method: 'get',
								headers: {
									'Content-Type': 'application/json'
								}
							})
							.then(response => response.json())
							.then(data => {

									this.formItem.title = data.data.title
									this.formItem.detail = data.data.detail
									this.formItem.isprompt = data.data.isprompt==1?true:false
									this.formItem.date = data.data.date
									this.formItem.time = data.data.time
								}) 
			}

		}
	}
</script>