<style scoped>
	.layout {
		border: 1px solid #d7dde4;
		background: #f5f7f9;
		position: relative;
		border-radius: 4px;
		/* 	overflow: hidden; */
		height: 100vh;
	}

	.layout-logo {
		width: 100px;
		height: 30px;
		background: #5b6270;
		border-radius: 3px;
		float: left;
		position: relative;
		top: 15px;
		left: 20px;
	}

	.layout-nav {
		width: 520px;
		margin: 0 auto;
		margin-right: 20px;
		float: right;
		color: white;
	}

	.layout-footer-center {
		text-align: center;
	}

	.layout .ivu-layout-header {
		/* background: #191a23; */
		background: rgb(45, 140, 240);
	}

	.layout-topMiddleBottom .ivu-menu-light {
		background: none;
		color: #fff
	}
</style>
<template>
	<div class="layout">
		<Layout>
			<Header>
				<Menu mode="horizontal" theme="white" active-name="1">
					<div style="float:left;">
						<img alt="logo" src="../assets/minlogo.png">
					</div>
					<div class="layout-nav">
						<MenuItem v-for="i in oprate" :name="i.name" :key="i.name" @click="newoprate(i.name)">
						<Icon :type="i.type"></Icon>
						{{i.opration}}
						</MenuItem>
					</div>
				</Menu>
			</Header>
			<Content :style="{padding: '0 50px'}">

				<Tabs value="name1" v-show="tab" :animated="false" @on-click="showAll">

					<TabPane v-for="t in type" :label="t.label" :name="t.name" :key="t.name">

						<Card>
							<div style="min-height: 430px;">
								<div v-if="t.component=='Allthings'" @click="showAllThing">
									<Allthings @editupdate="upedit"/>
								</div>
								<div v-else-if="t.component=='Allprompt'" @click="showAllPrompt">
									<Allprompt />
								</div>

								<div v-else-if="t.component=='Todayprompt'" @click="showTodayPrompt">

									<Todayprompt />

								</div>
								<div v-else @click="showCirclePrompt">
									<Circleprompt />
								</div>

							</div>
						</Card>
					</TabPane>

				</Tabs>

				<div v-show="card">
					<br>
					<Card>
						<div style="min-height: 460px;">
							<div v-if="newswitch=='Newthing'">
								<Newthing />
							</div>
							<div v-else-if="newswitch=='Newprompt'">
								<Newprompt />
							</div>
							<div v-else-if="newswitch=='Editthing'">
								<Editthing :id="thingid" @goback="golayout"/>
							</div>

						</div>
					</Card>
				</div>

			</Content>

			<div style="width:600px;margin:0 auto; padding:20px 0;">
				<Footer class="layout-footer-center"><span style="height:20px;line-height:20px; color:#939393;">2022-{{y}} &copy; Note Book</span> &nbsp; &nbsp;<a target="_blank"
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32041102000968"
						style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><span style="height:20px;line-height:20px; color:#939393;">苏公网安备 32041102000968号</span></a></Footer>
				<!-- <div style="text-align:center">
					<a target="_blank"
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32041102000968"
						style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
							src="../assets/beian.png" style="float:left;" />
						<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">苏公网安备
							32041102000968号</p>
					</a>
				</div> -->
			</div>
		</Layout>
	</div>
</template>
<script>
	import {
		resolveComponent
	} from 'vue'
	import Allthings from './Allthings'
	import Allprompt from './Allprompt'
	import Todayprompt from './Todayprompt'
	import Circleprompt from './Circleprompt'
	import Newthing from './Newthing'
	import Newprompt from './Newprompt'
	import Store from '../store'
	import Editthing from './Editthing.vue'

	export default {
		data() {
			return {
				type: [{
						name: "当日提醒",
						component: "Todayprompt",
						label: (h) => {
							return h('div', [
								h('span', '当日提醒'),
								h(resolveComponent('Badge'), {
									count: this.count.todayprompt.length
								})
							])
						}
					},
					{
						name: "全部记事",
						component: "Allthings",
						method: 'showallthing',
						label: '全部记事'
					},
					{
						name: "全部提醒",
						component: "Allprompt",
						label: '全部提醒'

					},
					{
						name: "周期提醒",
						component: "periodprompt",
						label: '周期提醒'
					}
				],
				oprate: [{
						name: 1,
						type: "ios-paper",
						opration: "新建记事",
						onclick: "newthing"
					},
					{
						name: 2,
						type: "ios-navigate",
						opration: "新建提醒",
						onclick: "newprompt"
					},
					{
						name: 3,
						type: "md-home",
						opration: "首页",
						onclick: "backhome"
					},
					{
						name: 4,
						type: "md-power",
						opration: "退出",
						onclick: "logout"
					},

				],
				tab: true,
				card: false,
				newswitch: "Newprompt",
				count: Store.state,
				tid: "",
				sid: "",
				thingid:""

			}
		},
		mounted() {


			this.sid = setInterval(function() {
				Store.showAll('showallthing')
				Store.showAll('showallprompt')
				Store.showAll('showtodayprompt')
				Store.showAll('showcircleprompt')
				console.log("定时刷新首页...");
			}, 60000);
			//let ws = new WebSocket("ws://127.0.0.1:2345");
			//let ws = new WebSocket("ws://146.56.193.219:2345");
			let ws = new WebSocket("wss://www.yssit.cn/wss");
			let _this = this;
			//定义提醒框位置等配置信息
			this.$Notice.config({
				top: 60
			})
			ws.onopen = function() {
				console.log("连接成功");

				ws.send(JSON.stringify({
					"type": "info",
					"uid": _this.count.user
				}));

				_this.tid = setInterval(function() {
					ws.send(JSON.stringify({
						"type": _this.count.user + ":peng！"
					}));
					console.log("发送心跳...");
				}, 10000);

			}
			ws.onclose = function() {
				console.log("connection close...")
				clearInterval(_this.tid)
			}

			ws.onmessage = function(e) {

				let data = JSON.parse(e.data);
				//	console.log(data);
				let title = data.title,
					prompttime = data.prompttime;
				if (title != undefined) {
					//		console.log("提醒标题：" + title);
					_this.$Notice.info({
						title: '到期提醒',
						desc: `提醒内容：
						${title};
						提醒时间：
						${prompttime}。`,
						duration: 0
					});

				}
			}

			ws.onerror = function(error) {
				//如果出现连接、处理、接收、发送数据失败的时候触发onerror事件
				console.log("error:", error);
			}

		},
		methods: {
			newoprate(i) {
				if (i == 1) {
					this.tab = false;
					this.card = true;
					this.newswitch = "Newthing";
				} else if (i == 2) {
					this.tab = false;
					this.card = true;
					this.newswitch = "Newprompt";
				} else if (i == 3) {
					this.tab = true;
					this.card = false;
				} else {
					Store.setLogout()
					clearInterval(this.tid)
					clearInterval(this.sid)
					this.$router.push('/login')
				}
			},
			showAllThing() {
				Store.showAll('showallthing')

			},
			showAllPrompt() {
				Store.showAll('showallprompt')
			},
			showTodayPrompt() {
				Store.showAll('showtodayprompt')
			},
			showCirclePrompt() {
				Store.showAll('showcircleprompt')
			},
			showAll() {
				Store.showAll('showallthing')
				Store.showAll('showallprompt')
				Store.showAll('showtodayprompt')
				Store.showAll('showcircleprompt')
			},
			upedit(id){
				
				this.tab = false;
				this.card = true;
				this.newswitch='Editthing';
				this.thingid=id;
			},
			golayout(){
				this.tab = true;
				this.card = false;
			}

		},
		computed: {
			y() {
				return (new Date()).getFullYear()
			}

		},
		components: {
			Allthings,
			Allprompt,
			Todayprompt,
			Circleprompt,
			Newthing,
			Newprompt,
			Editthing
		}
	}
</script>