import {
	createApp
} from 'vue'
import Router from './routes'
import ViewUIPlus from 'view-ui-plus'
import App from './App.vue'
import 'view-ui-plus/dist/styles/viewuiplus.css'

const app = createApp(App)
app.use(Router)
app.use(ViewUIPlus).mount('#app')
