<template>
	<Form ref="formItem" :model="formItem" :rules="ruleValidate" :label-width="80">
		<FormItem label="事项标题" prop="title">
			<Input v-model="formItem.title" placeholder="请输入事项标题"></Input>
		</FormItem>
		<FormItem label="事项说明" prop="detail">
			<Input v-model="formItem.detail" type="textarea" :autosize="{minRows: 2,maxRows: 5}"
				placeholder="请输入事项说明"></Input>
		</FormItem>
		<FormItem label="提醒时间">
			<Row>
				<Col span="5">
				<FormItem prop="date">
					<DatePicker type="date" placeholder="请选择日期" v-model="formItem.date"></DatePicker>
				</FormItem>
				</Col>
				<Col span="2" style="text-align: center">-</Col>
				<Col span="5">
				<FormItem prop="time">
					<TimePicker type="time" placeholder="请选择时间" v-model="formItem.time"></TimePicker>
				</FormItem>
				</Col>
			</Row>
		</FormItem>
		<FormItem label="周期提醒">
			<RadioGroup v-model="formItem.isinterval">
				<Radio label="1">是</Radio>
				<Radio label="0">否</Radio>
			</RadioGroup>
		</FormItem>
		<FormItem label="间隔时间" v-show="formItem.isinterval=='1'">
			<Row>
				<Col span="2">
				<InputNumber :max="100" :min="1" v-model="formItem.interval" />
				</Col>

				<Col span="3">
				<Select v-model="formItem.intervaltype" disabled>
					<Option value="day">天</Option>
					<Option value="hour">小时(暂未启用,静候佳音...)</Option>
				</Select>
				</Col>
			</Row>
		</FormItem>


		<FormItem>
			<Button type="primary" :disabled="subflag" @click="handleSubmit('formItem')">提交</Button>
			<Button style="margin-left: 8px" @click="handleReset('formItem')">取消</Button>
		</FormItem>
	</Form>
</template>
<script>
	import Store from '../store'
	export default {
		data() {
			return {
				subflag: false,
				formItem: {
					title: '',
					isinterval: '0',
					date: '',
					time: '',
					detail: '',
					interval: '1',
					intervaltype: 'day'

				},
				ruleValidate: {
					title: [{
						required: true,
						message: '事项标题不能为空',
						trigger: 'blur'
					}],
					detail: [{
						type: 'string',
						max: 200,
						message: '最多不能超过200字',
						trigger: 'blur'
					}],
					date: [{
						required: true,
						message: '日期不能为空',
						type: 'date',
						trigger: 'blur'
					}],
					time: [{
						required: true,
						message: '时间不能为空',
						type: 'string',
						trigger: 'blur'
					}]
				},
				sharedState: Store.state
			}
		},
		computed: {
			prompttime() {
				let date = new Date(this.formItem.date)
				let year = date.getFullYear()
				let month = Number(date.getMonth()) + 1
				month = month < 10 ? '0' + month : month
				let day = date.getDate()
				day = day < 10 ? '0' + day : day
				date = [year, month, day].join('-')

				return date + ' ' + this.formItem.time
			},
			randinfo() {
				let info = ['info', 'success', 'warning', 'error']
				let num = Math.floor(Math.random() * 4)
				return info[num]
			}
		},
		methods: {

			handleSubmit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						this.subflag = true;
						const data = {
							'title': this.formItem.title,
							'detail': this.formItem.detail,
							'isinterval': this.formItem.isinterval,
							'status': 'notdo',
							'prompttime': this.prompttime,
							'nexttime': this.prompttime,
							'interval': this.formItem.interval,
							'intervaltype': this.formItem.intervaltype,
							'info': this.randinfo,
							'user': this.sharedState.user,
							'cacheKey': this.sharedState.cacheKey,
							'token': this.sharedState.token,
							'islogin': 0 //标明非登录
						};
						fetch('abc/addprompt', {
								method: 'post',
								body: JSON.stringify(data),
								headers: {
									'Content-Type': 'application/json'
								}
							})
							.then(response => response.json())
							.then(data => {
								if (data.status == 1) {
									this.$Message.success('提交成功!');
									this.formItem.title = ''
									this.formItem.detail = ''
									this.formItem.isinterval = '0'
									this.formItem.date = ''
									this.formItem.time = ''
									this.formItem.interval = '1'
									this.formItem.intervaltype = 'day'
									setTimeout(() => this.subflag = false, 1000)
									Store.showAll('showallprompt')
									Store.showAll('showtodayprompt')
									Store.showAll('showcircleprompt')
								} else {
									this.$Message['error']({
										background: true,
										content: data.message
									});
									setTimeout(() => this.subflag = false, 1000)
								}
							});

					} else {
						this.$Message.error('验证不通过!');
					}
				});
			},
			handleReset(name) {

				this.$refs[name].resetFields();
			}

		}
	}
</script>