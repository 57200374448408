<template>

	<div class="logo">
		<img alt="logo" src="../assets/logo.png">
	</div>
	<div class="login">
		<Login @on-submit="handleSubmit">
			<UserName name="username" :value="username" />
			<Password name="password" :value="password" />
			<div class="demo-auto-login">
				<Checkbox v-model="autoLogin" size="large">记住密码</Checkbox>
				<a @click.prevent="register">注册用户</a>
			</div>
			<Submit />
		</Login>

		<br />
		<div id="qqLogin">
			<a href="#" @click='toLogin'>
				<img src="../assets/Connect_logo_7.png"></a>

		</div>
	</div>
	<div style="width:300px;margin:0 auto; padding:20px 0;">
		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32041102000968"
			style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
				src="../assets/beian.png" style="float:left;" />
			<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">苏公网安备
				32041102000968号</p>
		</a>
	</div>

</template>
<script>
	import Store from '../store'

	export default {
		data() {
			return {
				autoLogin: true,
				username: '',
				password: ''
			}
		},
		methods: {
			handleSubmit(valid, {
				username,
				password
			}) {

				if (valid) {
					const data = {
						'username': username,
						'password': password,
						'islogin': 1 //标明登录
					};
					fetch('abc/login', {
							method: 'post',
							body: JSON.stringify(data),
							headers: {
								'Content-Type': 'application/json'
							}
						})
						.then(response => response.json())
						.then(data => {
							if (data.status == 1) {
								Store.setToken(data.data.user, data.data.cacheKey, data.data.token);
								//	Store.setLogin();
								this.$router.push('/layout')
							} else {
								this.$Message['error']({
									background: true,
									content: data.message
								});
							}
						}).catch(err => {
							this.$Message['error']({
								background: true,
								content: err
							});
						});
					if (this.autoLogin) {
						if (window.localStorage.getItem("user") != null) {
							if (window.localStorage.getItem("user") != username) {
								window.localStorage.setItem("user", username)
							} else if (window.atob(window.localStorage.getItem("pass")) != password) {
								window.localStorage.setItem("pass", window.btoa(password))
							}
						} else {
							window.localStorage.setItem("user", username)
							window.localStorage.setItem("pass", window.btoa(password))
						}
					} else {
						if (window.localStorage.getItem("user") != null) {
							window.localStorage.removeItem("user")
							window.localStorage.removeItem("pass")
						}
					}
				}
			},
			register() {
				//	Store.setRegister()
				this.$router.push('/register')
			},
			toLogin() {
				//以下为按钮点击事件的逻辑。注意这里要重新打开窗口
				//否则后面跳转到QQ登录，授权页面时会直接缩小当前浏览器的窗口，而不是打开新窗口
				/* 				window.open("/tologin", "TencentLogin",
									"width=450,height=320,menubar=0,scrollbars=1,resizable = 1, status = 1, titlebar = 0, toolbar = 0, location = 1 "
								);
				 */

				window.location.href = "/tologin";

			}
		},
		created() {

			if (window.localStorage.getItem("user") != null) {
				this.username = window.localStorage.getItem("user")
				this.password = window.atob(window.localStorage.getItem("pass"))
			}

		}
	}
</script>
<style scoped>
	/* 	#app{
		margin-top: 60px;
	} */

	.logo {
		text-align: center;
	}

	.login {
		width: 400px;
		margin: 0 auto !important;
	}

	.demo-auto-login {
		margin-bottom: 24px;
		text-align: left;
	}

	.demo-auto-login a {
		float: right;
	}

	#qqLogin {
		text-align: right;
	}
</style>