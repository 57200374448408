<style scoped>
	.title {
		font-size: 18px;
		font-weight: bold;

	}

	.content {
		font-size: 14px;
		padding-left: 5px;
	}
</style>
<template>
	<Timeline>
		<TimelineItem v-for="thing in sharedState.things" :key="thing.id">
			<p class="title">{{thing.title}}</p>
			<p class="content">{{thing.detail}}</p>
			<div style="display: flex;flex-direction: row;justify-content: center;margin-top: 30px;">
				<div style="width:60px;height:60px;padding-right: 60px;">
					<Result @click.stop="upstatus(thing.id)" type="success"></Result>
				</div>
				<div style="width:60px;height:60px;padding-left: 60px;">
					<Result @click.stop="upedit(thing.id)" type="warning"></Result>
				</div>
			</div>
		</TimelineItem>
	</Timeline>
</template>
<script>
	import Store from '../store'
	export default {
		data() {
			return {
				sharedState: Store.state,

			}
		},
		methods: {
			upstatus(id) {

				this.$Modal.confirm({
					title: '确认已经完成？',
					onOk: () => {
						if(Store.upstatus(id)){
							this.$Message.success('更新成功')
						}else{
							this.$Message.error('更新失败')
						}
						

					},
					onCancel: () => {
						this.$Message.info('已取消');
					}
				});


			},
			upedit(id){
				
				this.$emit('editupdate',id)
			}
		},
		mounted() {

			//Store.showAll('showallthing');
		},


	}
</script>
