<template>
	<div class='img'>
		<img alt="logo" src="../assets/logo.png">
	</div>
	<div id="reg">
		<Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
			<!-- 			<FormItem label="昵称" prop="name">
				<Input v-model="formValidate.name" placeholder="输入您的昵称"></Input>
			</FormItem> -->
			<FormItem label="登录名" prop="uname">
				<Input v-model="formValidate.uname" placeholder="输入您的登录名"></Input>
			</FormItem>
			<FormItem label="密码" prop="password">
				<Input v-model="formValidate.password" type="password" password placeholder="输入您的密码"></Input>
			</FormItem>
			<FormItem label="重复密码" prop="repassword">
				<Input v-model="formValidate.repassword" type="password" password placeholder="再次输入您的密码"></Input>
			</FormItem>
			<FormItem>
				<div class="img">
					<Button type="primary" :disabled="subflag" @click="handleSubmit('formValidate')">注册</Button>
					<Button @click="handleReset('formValidate')" style="margin-left: 8px">重置</Button>
					<Button type="success" @click="backLogin" style="margin-left: 8px">返回</Button>
				</div>
			</FormItem>
		</Form>
	</div>
</template>

<script>
	import Store from '../store'
	export default {
		data() {
			return {
				subflag: false,
				formValidate: {
					uname: '',
					password: '',
					repassword: ''
				},
				ruleValidate: {
					uname: [{
						required: true,
						message: '登录名不允许为空',
						trigger: 'blur'
					}],
					password: [{
							required: true,
							message: '密码不允许为空',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '密码不允许小于6位',
							trigger: 'blur'
						}
					],
					repassword: [{
							required: true,
							message: '重复密码不允许为空',
							trigger: 'blur'
						},
						{
							min: 6,
							message: '重复密码不允许小于6位',
							trigger: 'blur'
						}
					]

				}
			}
		},
		methods: {
			handleSubmit(name) {
				this.$refs[name].validate((valid) => {
					if (valid) {
						this.subflag = true;
						if (this.formValidate.password != this.formValidate.repassword) {
							this.$Message.error('两次输入的密码不一致!');
							return false
						}
						const data = {
							'uname': this.formValidate.uname,
							'password': this.formValidate.password,
						};
						fetch('abc/adduser', {
								method: 'post',
								body: JSON.stringify(data),
								headers: {
									'Content-Type': 'application/json'
								}
							})
							.then(response => response.json())
							.then(data => {
								if (data.status == 1) {
									this.$Message.success('注册成功!');
									setTimeout(() => this.subflag = false, 1000)
								} else {
									this.$Message['error']({
										background: true,
										content: data.message
									});
									setTimeout(() => this.subflag = false, 1000)
								}
							});
					} else {
						this.$Message.error('校验不通过!');
					}
				});
			},
			handleReset(name) {
				this.$refs[name].resetFields();
			},
			backLogin() {
				Store.backLogin()
				this.$router.push('/login')
			}
		},

	}
</script>

<style scoped>
	.img {
		text-align: center;
	}

	#reg {
		width: 500px;
		margin: 0 auto;
	}
</style>