<template>
	<Alert :type="prompt.info" v-for="prompt in sharedState.prompts" :key="prompt.id">
		{{prompt.title}}

		<template #desc>{{prompt.detail}}</template>
		<div @click.stop="uppromptstatus(prompt.id)" style="width:30px;height:80px;margin:0 auto">
			<Result type="success"></Result>
		</div>

	</Alert>

</template>
<script>
	import Store from '../store'
	export default {
		data() {
			return {
				sharedState: Store.state
			}
		},
		methods: {
			uppromptstatus(id) {

				this.$Modal.confirm({
					title: '确认结束提醒？',
					onOk: () => {

						if (Store.uppromptstatus(id)) {
							this.$Message.success('更新成功')

							Store.showAll('showtodayprompt')

						} else {
							this.$Message.error('更新失败')
						}

					},
					onCancel: () => {
						this.$Message.info('已取消');
					}
				});


			}
		},
		computed: {

		}
	}
</script>