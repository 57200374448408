<template>
	<div class="page">
		<router-view></router-view>
	</div>
</template>

<script>
	import Store from './store'

	export default {
		name: 'App',
		data() {
			return {

				sharedState: Store.state

			}
		},
		methods: {
			getURLParams(url) {
				let param = url.split('?')[1]; //获取?之后的字符串
				let paramsArray = param.split('&'); //参数字符串分割为数组
				let paramResult = {};
				//遍历数组，拿到json对象
				paramsArray.forEach((item, index, paramsArray) => {
					paramResult[paramsArray[index].split('=')[0]] = paramsArray[index].split('=')[1];
				})
				return paramResult;
			}
		},
		mounted() {
			//QQ登陆
			if (window.location.href.indexOf('?') > -1) {
				var param = this.getURLParams(window.location.href)
				if (param.h == 1) {
					/* 					Store.state.isLayout = true
										Store.state.isLogin = false */
					this.$router.push('/layout')
					Store.setToken(param.u, param.c, param.t)
					/* 					Store.state.user = param.u
										Store.state.cacheKey = param.c
										Store.state.token = param.t */
				}
			}


		},
		updated() {
			//	this.sharedState.isLogin = window.localStorage.getItem("isLogin")
			//	this.sharedState.isLayout = window.localStorage.getItem("isLayout")
		}

	}
</script>

<style scoped>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 1px;
	}

	.page {

		background-color: rgb(45, 140, 240, 0.2);

		height: 100vh;

		display: flex;
		flex-direction: column;
		justify-content: center;


	}
</style>