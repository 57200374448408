import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'


import Login from '../pages/Login.vue'
import Register from '../pages/Register.vue'
import Layout from '../pages/Layout.vue'
import Store from '../store'


//路由数组
const routes = [

	{
		//基本格式
		path: "/",

		name: "Index",

		component: Login,

		children: []

	},
	{
		//基本格式
		path: "/login",

		name: "Login",

		component: Login,

		children: []

	},
	{
		//基本格式
		path: "/register",

		name: "Register",

		component: Register,

		children: []

	},
	{
		//基本格式
		path: "/layout",

		name: "Layout",

		component: Layout,

		children: []

	}

]

//路由对象
const router = createRouter({

	history: createWebHistory(),
	//history: createWebHashHistory(),
	routes //上面的路由数组

})

router.beforeEach((to, from, next) => {

	//不是系统提供的预置页面都跳转到登录页
	if (to.name !== 'Index' && to.name !== 'Login' && to.name !== 'Register' && to.name !== 'Layout') {

		next({
			name: 'Login'
		})
	}
	//实现刷新后更新store用户信息
	if (to.name == 'Layout') {
		let user = window.localStorage.getItem('stateuser')
		let cacheKey = window.localStorage.getItem('cacheKey')
		let token = window.localStorage.getItem('token')
		//用户不存在跳转到登陆页
		if (user == null) {
			next({
				name: 'Login'
			})
		} else {
			//根据本地缓存更新store数据
			Store.updateUser(user, cacheKey, token)
			next()
		}

	}
	next()
})



//导出路由对象，在main.js中引用
export default router